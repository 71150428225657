import React, { useState } from 'react'

import './component1.css'

const Component1 = (props) => {
  const [rab3, setRab3] = useState(false)
  const [tab1, setTab1] = useState(true)
  const [tab2, setTab2] = useState(false)
  return (
    <div className="component1-container">
      <div
        onClick={() => {
          setTab1(true)
          setTab2(false)
          setRab3(false)
        }}
        className="component1-container01"
      >
        {!tab1 && (
          <div className="component1-container02">
            <span className="component1-text">Cold Email Outreach</span>
          </div>
        )}
        {tab1 && (
          <div className="component1-container03">
            <div className="component1-container04 linear-gradient">
              <span className="component1-text1">
                Monitoring Potential Future Decision-Makers
              </span>
            </div>
            <div className="component1-container05">
              <span className="component1-text2 text">
                We understand that you have a specific buyer persona in mind.
                While some tools can identify the individual responsible for
                purchasing decisions within your customer&apos;s organization,
                they fall short in tracking the entire department. Now, imagine
                if someone from that department moves to another employer.
                Previously they&apos;ve already used your tool. With their new
                role potentially offering greater decision-making power, there’s
                a prime opportunity to reintroduce your tool in this new
                setting. We help you capitalize on these shifts, ensuring your
                solution stays top-of-mind across transitions.
              </span>
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => {
          setTab1(false)
          setTab2(true)
          setRab3(false)
        }}
        className="component1-container06"
      >
        {!tab2 && (
          <div className="component1-container07">
            <span className="component1-text3">Automated Deal discovery</span>
          </div>
        )}
        {tab2 && (
          <div className="component1-container08">
            <div className="component1-container09 linear-gradient">
              <span className="component1-text4">
                Partnering with Our Customers
              </span>
            </div>
            <div className="component1-container10">
              <span className="component1-text5 text">
                We have many customers in our network with similar buyer
                personas. Why not partner with them? They might have customers
                you haven&apos;t reached yet, and vice versa. By collaborating,
                you can introduce each other&apos;s offerings, significantly
                enhancing the likelihood of closing deals. After all,
                recommendations carry a very high conversion rate.
              </span>
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => {
          setTab1(false)
          setTab2(false)
          setRab3(true)
        }}
        className="component1-container11"
      >
        {!rab3 && (
          <div className="component1-container12">
            <span className="component1-text6">Continious support</span>
          </div>
        )}
        {rab3 && (
          <div className="component1-container13">
            <div className="component1-container14 linear-gradient">
              <span className="component1-text7">
                AI-Powered Lead Generation
              </span>
            </div>
            <div className="component1-container15">
              <span className="component1-text8 text">
                Especially for smaller teams it is crucial to determine your ICP
                and the Buyer Persona before starting automated outreach. we are
                the all-in-one solution for that.
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Component1
