import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'

import Calendly from './calendly'
import './top-bar.css'

const TopBar = (props) => {
  const [home, setHome] = useState(props.home || false)
  const [mailCloud, setMailCloud] = useState(props.mailCloud || false)
  const [pricing, setPricing] = useState(props.pricing || false)
  const [schedule, setSchedule] = useState(props.schedule || false)
  const [contact, setContact] = useState(props.contact || false)
  const [product, setProduct] = useState(props.product || false)
  const [aboutUs, setAboutUs] = useState(props.aboutUs || false)

  useEffect(() => {
    setSchedule(props.schedule);
  }, [props.schedule]);
  
  return (
    <div className="top-bar-container">
      <div className="top-bar-container01">
        <img alt="image" src="/logo_txt_w-200h.png" className="top-bar-image" />
        <div className="top-bar-container02">
          <Link to="/" className="top-bar-navlink">
            <div className="top-bar-container03">
              {!home && <span className="top-bar-text menu-text">Home</span>}
              {home && <span className="menu-text-bold">Home</span>}
              <div className="top-bar-container04">
                {home && <div className="top-bar-container05"></div>}
              </div>
            </div>
          </Link>
          <Link to="/mail-cloud" className="top-bar-navlink1">
            <div className="top-bar-container06">
              {!mailCloud && <span className="top-bar-text02">Mail-Cloud</span>}
              {mailCloud && <span className="top-bar-text03">Mail-Cloud</span>}
              <div className="top-bar-container07">
                {mailCloud && <div className="top-bar-container08"></div>}
              </div>
            </div>
          </Link>
          <Link to="/product" className="top-bar-navlink2">
            <div className="top-bar-container09">
              {!product && <span className="menu-text">Product</span>}
              {product && (
                <span className="top-bar-text05 menu-text-bold">Product</span>
              )}
              <div className="top-bar-container10">
                {product && <div className="top-bar-container11"></div>}
              </div>
            </div>
          </Link>
          <Link to="/pricing" className="top-bar-navlink3">
            <div className="top-bar-container12">
              {!pricing && (
                <span className="top-bar-text06 menu-text">Pricing</span>
              )}
              {pricing && (
                <span className="top-bar-text07 menu-text-bold">Pricing</span>
              )}
              <div className="top-bar-container13">
                {pricing && <div className="top-bar-container14"></div>}
              </div>
            </div>
          </Link>
          <Link to="/about-us" className="top-bar-navlink4">
            <div className="top-bar-container15">
              {!aboutUs && (
                <span className="top-bar-text08 menu-text">About us</span>
              )}
              {aboutUs && (
                <span className="top-bar-text09 menu-text-bold">About us</span>
              )}
              <div className="top-bar-container16">
                {aboutUs && <div className="top-bar-container17"></div>}
              </div>
            </div>
          </Link>
          <Link to="/contact" className="top-bar-navlink5">
            <div className="top-bar-container18">
              {!contact && (
                <span className="top-bar-text10 menu-text">Contact</span>
              )}
              {contact && (
                <span className="top-bar-text11 menu-text-bold">Contact</span>
              )}
              <div className="top-bar-container19">
                {contact && <div className="top-bar-container20"></div>}
              </div>
            </div>
          </Link>
        </div>
        <div className="top-bar-container21">
          <div
            onClick={() => setSchedule(!schedule)}
            className="top-bar-container22"
          >
            <span className="button-text">SCHEDULE A DEMO</span>
          </div>
          <a href="https://dashboard.traffls.com/" className="top-bar-link">
            <div className="top-bar-container23 linear-gradient">
              <span className="button-text">Login</span>
            </div>
          </a>
        </div>
      </div>
      {schedule && (
        <div className="top-bar-container24">
          <div
            onClick={() => setSchedule(false)}
            className="top-bar-container25"
          >
            <div className="top-bar-container26">
              <div className="top-bar-container27">
                <React.Fragment>
                  <React.Fragment>
                    {/* Calendly inline widget begin */}
                    <div
                      className="calendly-inline-widget"
                      data-url="https://calendly.com/traffls/30min"
                      style={{ minWidth: '100%', height: '100%' }}
                    />
                    <Script
                      type="text/javascript"
                      src="https://assets.calendly.com/assets/external/widget.js"
                      async={true}
                    />
                    {/* Calendly inline widget end */}
                  </React.Fragment>
                </React.Fragment>
              </div>
            </div>
            <Calendly rootClassName="calendly-root-class-name"></Calendly>
          </div>
        </div>
      )}
    </div>
  )
}

export default TopBar
