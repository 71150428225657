import React from 'react'
import { Link } from 'react-router-dom'

import './bottom-bar.css'

const BottomBar = (props) => {
  return (
    <div className="bottom-bar-container">
      <div className="bottom-bar-container01">
        <div className="bottom-bar-container02">
          <Link to="/" className="bottom-bar-navlink">
            <div className="bottom-bar-container03">
              <img
                alt="image"
                src="/logo_txt_w-200h.png"
                className="bottom-bar-image"
              />
            </div>
          </Link>
          <span className="text">
            Elevate your sales game by thinking outside the box
          </span>
        </div>
        <div className="bottom-bar-container04">
          <a
            href="https://www.linkedin.com/company/traffls/"
            target="_blank"
            rel="noreferrer noopener"
            className="bottom-bar-link"
          >
            <div className="bottom-bar-container05">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="bottom-bar-icon"
              >
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
            </div>
          </a>
          <a
            href="https://www.instagram.com/traffls_com/"
            target="_blank"
            rel="noreferrer noopener"
            className="bottom-bar-link1"
          >
            <div className="bottom-bar-container06">
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="bottom-bar-icon2"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </div>
          </a>
        </div>
      </div>
      <div className="bottom-bar-container07">
        <div className="bottom-bar-container08">
          <span className="titel-2">EXPLORE</span>
          <Link to="/" className="bottom-bar-navlink1">
            <div className="bottom-bar-container09">
              <span className="bottom-bar-text02 text">Home</span>
            </div>
          </Link>
          <Link to="/mail-cloud" className="bottom-bar-navlink2">
            <div className="bottom-bar-container10">
              <span className="bottom-bar-text03 text">Mail Cloud</span>
            </div>
          </Link>
          <Link to="/product" className="bottom-bar-navlink3">
            <div className="bottom-bar-container11">
              <span className="bottom-bar-text04 text">Product</span>
            </div>
          </Link>
          <Link to="/pricing" className="bottom-bar-navlink4">
            <div className="bottom-bar-container12">
              <span className="bottom-bar-text05 text">Pricing</span>
            </div>
          </Link>
          <Link to="/about-us" className="bottom-bar-navlink5">
            <div className="bottom-bar-container13">
              <span className="bottom-bar-text06 text">About us</span>
            </div>
          </Link>
        </div>
        <div className="bottom-bar-container14">
          <span className="bottom-bar-text07 titel-2">SUPPORT</span>
          <Link to="/contact" className="bottom-bar-navlink6">
            <div className="bottom-bar-container15">
              <span className="bottom-bar-text08 text">Contact Us</span>
            </div>
          </Link>
          <Link to="/privacy-policy" className="bottom-bar-navlink7">
            <div className="bottom-bar-container16">
              <span className="bottom-bar-text09 text">Privacy Policy</span>
            </div>
          </Link>
          <Link to="/imprint" className="bottom-bar-navlink8">
            <div className="bottom-bar-container17">
              <span className="bottom-bar-text10 text">Imprint</span>
            </div>
          </Link>
        </div>
        <div className="bottom-bar-container18">
          <span className="bottom-bar-text11 titel-2">Contact info</span>
          <a
            href="mailto:info@up2data.io?subject="
            className="bottom-bar-link2"
          >
            <div className="bottom-bar-container19">
              <span className="text">info@traffls.com</span>
            </div>
          </a>
          <span className="text">
            <span>Up2Data GmbH</span>
            <br></br>
            <span>Prinz-Ludwig-Straße 4</span>
            <br></br>
            <span>80333 München</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default BottomBar
