import React from 'react'

import { Helmet } from 'react-helmet'

import TopBar from '../components/top-bar'
import BottomBar from '../components/bottom-bar'
import './mail-cloud.css'

const MailCloud = (props) => {
  return (
    <div className="mail-cloud-container">
      <Helmet>
        <title>Mail-Cloud - Traffls</title>
        <meta property="og:title" content="Mail-Cloud - Traffls" />
      </Helmet>
      <div className="mail-cloud-container01">
        <TopBar></TopBar>
        <div className="mail-cloud-container02">
          <div className="mail-cloud-container03">
            <div className="mail-cloud-container04">
              <span className="mail-cloud-text">- Mail-Cloud</span>
              <span className="mail-cloud-text01">
                Outbound Sales is incredible - we help you to scale it
              </span>
            </div>
            <span>
              20% of all employees change their job each year. So do also the
              people that you closed before. Their new job is your new
              opportunity
            </span>
            <div className="mail-cloud-container05 box-shadow linear-gradient">
              <span className="button-text">SCHEDULE A DEMO</span>
            </div>
          </div>
          <div className="mail-cloud-container06">
            <div className="mail-cloud-container07">
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className="mail-cloud-image"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mail-cloud-container08"></div>
      <div className="mail-cloud-container09">
        <div className="mail-cloud-container10">
          <div className="mail-cloud-container11">
            <span className="mail-cloud-text04">- Our Product</span>
            <span className="mail-cloud-text05">
              Our automated Cold Outreach tool will help you to scale fast
            </span>
          </div>
          <span className="mail-cloud-text06">
            <span>Scaling Sales is a numbers game.</span>
            <br></br>
            <span>
              But we also help you to achieve a high quality of scalable
              outreach
            </span>
          </span>
          <div className="mail-cloud-container12">
            <div className="mail-cloud-container13">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container14">
              <span className="mail-cloud-text10">
                Automated AI based Cold Outreach
              </span>
            </div>
          </div>
          <div className="mail-cloud-container15">
            <div className="mail-cloud-container16">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon02">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container17">
              <span className="mail-cloud-text11">
                CRM Analysis for more Sales Opportunities
              </span>
            </div>
          </div>
          <div className="mail-cloud-container18">
            <div className="mail-cloud-container19">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon04">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container20">
              <span className="mail-cloud-text12">
                High Quality leads directly in your CRM
              </span>
            </div>
          </div>
          <div className="mail-cloud-container21">
            <div className="mail-cloud-container22">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon06">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container23">
              <span className="mail-cloud-text13">
                Continuous Expert Support
              </span>
            </div>
          </div>
        </div>
        <div className="mail-cloud-container24">
          <img alt="image" src="/img_2.svg" className="mail-cloud-image1" />
        </div>
      </div>
      <div className="mail-cloud-container25">
        <div className="mail-cloud-container26">
          <img alt="image" src="/img_1.svg" className="mail-cloud-image2" />
        </div>
        <div className="mail-cloud-container27">
          <div className="mail-cloud-container28">
            <span className="mail-cloud-text14">- Our advantages</span>
            <span className="mail-cloud-text15">
              With us you are free in the definition of your cascades
            </span>
          </div>
          <span className="mail-cloud-text16">
            Most tools have a fixed amount of sequence steps or get costly very
            easily. We have no interest in that. You should be free in how many
            campaigns you are launching with as many steps as you wish
          </span>
        </div>
      </div>
      <div className="mail-cloud-container29">
        <div className="mail-cloud-container30">
          <div className="mail-cloud-container31">
            <span className="mail-cloud-text17">- Our Features</span>
            <span className="mail-cloud-text18">
              <span>You only have to focus on the positives.</span>
              <br></br>
              <span>We do the rest</span>
            </span>
          </div>
          <span className="mail-cloud-text22">
            <span>
              Unlike other tools, we will only forward you the positive replies.
            </span>
            <br></br>
            <span>The others are taken care of.</span>
          </span>
          <div className="mail-cloud-container32">
            <div className="mail-cloud-container33">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon08">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container34">
              <span className="mail-cloud-text26">
                Filtering of positive replies
              </span>
            </div>
          </div>
          <div className="mail-cloud-container35">
            <div className="mail-cloud-container36">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon10">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container37">
              <span className="mail-cloud-text27">High response rates</span>
            </div>
          </div>
          <div className="mail-cloud-container38">
            <div className="mail-cloud-container39">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon12">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container40">
              <span className="mail-cloud-text28">
                High Quality leads directly in your CRM
              </span>
            </div>
          </div>
          <div className="mail-cloud-container41">
            <div className="mail-cloud-container42">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon14">
                <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container43">
              <span className="mail-cloud-text29">
                Weekly Campaign Meetings
              </span>
            </div>
          </div>
        </div>
        <div className="mail-cloud-container44">
          <img alt="image" src="/img_4.svg" className="mail-cloud-image3" />
        </div>
      </div>
      <div className="mail-cloud-container45">
        <div className="mail-cloud-container46">
          <div className="mail-cloud-container47">
            <span className="mail-cloud-text30">
              <span>Learn about our </span>
              <span>company success </span>
              <span>stories</span>
            </span>
            <span>
              Start your sales journey today to get the most out of your efforts
            </span>
          </div>
          <div className="mail-cloud-container48">
            <div className="mail-cloud-container49">
              <span className="mail-cloud-text35">10K+</span>
              <span>Sales Calls</span>
            </div>
            <div className="mail-cloud-container50">
              <span className="mail-cloud-text37">125K+</span>
              <span>
                <span>Data enriched</span>
                <br></br>
              </span>
            </div>
            <div className="mail-cloud-container51">
              <span className="mail-cloud-text41">15+</span>
              <span>Years Experience</span>
            </div>
            <div className="mail-cloud-container52">
              <span className="mail-cloud-text43">8+</span>
              <span>ROI on average</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mail-cloud-container53">
        <div className="mail-cloud-container54">
          <span className="mail-cloud-text45">
            The process to start is as easy as it can be
          </span>
          <span className="mail-cloud-text46">
            <span>
              After we did the setup together with you, you can start with your
              Outbound Campaigns.
            </span>
            <br></br>
            <span>
              We will then on a weekly meeting iterate the success to get the
              most out for you.
            </span>
          </span>
        </div>
        <div className="mail-cloud-container55">
          <div className="mail-cloud-container56">
            <div className="mail-cloud-container57">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon16">
                <path d="M854 896v-554h-684v554h684zM854 128q34 0 59 26t25 60v682q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-682q0-34 25-60t59-26h44v-86h84v86h428v-86h84v86h44z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container58">
              <span className="mail-cloud-text50">Onboarding</span>
              <span>
                In the Onboarding we will discuss the blacklisting, the ICP, the
                buyer persona, the sequences, and the number of campaigns
              </span>
            </div>
          </div>
          <div className="mail-cloud-container59">
            <div className="mail-cloud-container60">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon18">
                <path d="M512 662q62 0 106-44t44-106-44-106-106-44-106 44-44 106 44 106 106 44zM830 554l90 70q14 10 4 28l-86 148q-8 14-26 8l-106-42q-42 30-72 42l-16 112q-4 18-20 18h-172q-16 0-20-18l-16-112q-38-16-72-42l-106 42q-18 6-26-8l-86-148q-10-18 4-28l90-70q-2-14-2-42t2-42l-90-70q-14-10-4-28l86-148q8-14 26-8l106 42q42-30 72-42l16-112q4-18 20-18h172q16 0 20 18l16 112q38 16 72 42l106-42q18-6 26 8l86 148q10 18-4 28l-90 70q2 14 2 42t-2 42z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container61">
              <span className="mail-cloud-text52">Outbound</span>
              <span>
                After that we are good to go and the outbound campaigns can be
                launched. You will get then deals directly in your inbox
              </span>
            </div>
          </div>
          <div className="mail-cloud-container62">
            <div className="mail-cloud-container63">
              <svg viewBox="0 0 1024 1024" className="mail-cloud-icon20">
                <path d="M512 682.667h-341.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-426.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h682.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v426.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501zM469.333 768v85.333h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h341.333c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128v-85.333h298.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-426.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-682.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v426.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504z"></path>
              </svg>
            </div>
            <div className="mail-cloud-container64">
              <span className="mail-cloud-text54">Monitoring</span>
              <span>
                On a weekly basis we are discussing with you how to increase the
                success further to get you the highest ROI possible
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mail-cloud-container65">
        <div className="mail-cloud-container66">
          <span className="mail-cloud-text56">Subscribe Our Newsletter</span>
          <span className="mail-cloud-text57">
            <span>
              Enter your details to get business inspiration, trending
              solutions,
            </span>
            <br></br>
            <span>and consulting tips delivered to your inbox</span>
          </span>
        </div>
        <div className="mail-cloud-container67">
          <input
            type="text"
            placeholder="Enter your email address"
            className="mail-cloud-textinput"
          />
          <div className="mail-cloud-container68 linear-gradient">
            <svg viewBox="0 0 1024 1024" className="mail-cloud-icon22">
              <path d="M128 337.963l359.552 251.691c14.507 10.027 33.92 10.496 48.939 0l359.509-251.691v430.037c0 11.605-4.693 22.229-12.587 30.080s-18.475 12.587-30.080 12.587h-682.667c-11.605 0-22.229-4.693-30.080-12.587s-12.587-18.475-12.587-30.080zM42.667 256.512v511.488c0 35.328 14.507 67.371 37.547 90.453s55.125 37.547 90.453 37.547h682.667c35.328 0 67.371-14.507 90.453-37.547s37.547-55.125 37.547-90.453v-511.488c0-0.427 0-0.853 0-1.28-0.213-35.029-14.635-66.773-37.547-89.685-23.083-23.040-55.125-37.547-90.453-37.547h-682.667c-35.328 0-67.371 14.507-90.453 37.547-22.912 22.912-37.333 54.656-37.547 89.728 0 0.213 0 0.469 0 0.725zM891.477 236.971l-379.477 265.6-379.477-265.6c2.048-4.096 4.779-7.808 8.021-11.051 7.893-7.893 18.517-12.587 30.123-12.587h682.667c11.605 0 22.229 4.693 30.080 12.587 3.243 3.243 5.973 6.997 8.021 11.051z"></path>
            </svg>
          </div>
        </div>
      </div>
      <BottomBar></BottomBar>
    </div>
  )
}

export default MailCloud
