import React from 'react'

import PropTypes from 'prop-types'

import './success-stories-component.css'

const SuccessStoriesComponent = (props) => {
  return (
    <div
      className={`success-stories-component-container ${props.rootClassName} `}
    >
      <div className="success-stories-component-container1">
        <span className="success-stories-component-text titel">
          <span className="">Discover how our success stories can</span>
          <br className=""></br>
          <span className="">inspire your path to greatness!</span>
        </span>
        <span className="text">
          Leverage our expertise and your data to guarantee outstanding results
          for your sales department!
        </span>
      </div>
      <div className="success-stories-component-container2">
        <div className="success-stories-component-container3">
          <span className="text-3">5M+</span>
          <span className="text">Sales Calls</span>
        </div>
        <div className="success-stories-component-container4">
          <span className="text-3">125K+</span>
          <span className="text">
            <span className="">Data enriched</span>
            <br className=""></br>
          </span>
        </div>
        <div className="success-stories-component-container5">
          <span className="text-3">15+</span>
          <span className="text">Years Experience</span>
        </div>
        <div className="success-stories-component-container6">
          <span className="text-3">8+</span>
          <span className="text">ROI on average</span>
        </div>
      </div>
    </div>
  )
}

SuccessStoriesComponent.defaultProps = {
  rootClassName: '',
}

SuccessStoriesComponent.propTypes = {
  rootClassName: PropTypes.string,
}

export default SuccessStoriesComponent
