import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './calendly.css'

const Calendly = (props) => {
  return (
    <div className={`calendly-calendly box-shadow ${props.rootClassName} `}>
      <div className="calendly-container">
        <div className="calendly-container1">
          <React.Fragment>
            <React.Fragment>
              {/* Calendly inline widget begin */}
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/traffls/30min"
                style={{ minWidth: '100%', height: '100%' }}
              />
              <Script
                type="text/javascript"
                src="https://assets.calendly.com/assets/external/widget.js"
                async={true}
              />
              {/* Calendly inline widget end */}
            </React.Fragment>
          </React.Fragment>
        </div>
      </div>
      <div className="calendly-container2">
        <span className="calendly-text">Loading calendar...</span>
      </div>
    </div>
  )
}

Calendly.defaultProps = {
  text: 'Text',
  rootClassName: '',
}

Calendly.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Calendly
