import React, { useState } from 'react';

import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import TopBar from '../components/top-bar'
import Component2 from '../components/component2'
import SuccessStoriesComponent from '../components/success-stories-component'
import Component1 from '../components/component1'
import NewsletterComponent from '../components/newsletter-component'
import BottomBar from '../components/bottom-bar'
import './home.css'

const Home = (props) => {
  const [schedule, setSchedule] = useState(false)

  const handleButtonClick = () => {
    setSchedule(true);
  };
  return (
    <div className="home-container">
      <Helmet>
        <title>Traffls</title>
        <meta property="og:title" content="Traffls" />
      </Helmet>
      <div className="home-container01">
        <TopBar home={true} schedule={schedule} ></TopBar>
      </div>
      <div className="home-container02">
        <div className="home-container03">
          <div className="home-container04">
            <div className="home-container05">
              <div className="home-container06"></div>
              <div className="home-container07">
                <div className="home-container08">
                  <span className="big-titel">AI-powered sales growth</span>
                  <span className="home-text01 text">
                    Unleash the power of data with traffls! Our innovative
                    company uncovers hidden sales leads that your team never
                    even considered. Think outside the box and let AI reveal new
                    opportunities for your business. Get high-conversion leads
                    with ease and watch your sales soar!
                  </span>
                  <div onClick={handleButtonClick} className="home-container09 box-shadow linear-gradient">
                    <span className="button-text">SCHEDULE A DEMO</span>
                  </div>
                </div>
                <div className="home-container10">
                  <div className="home-container11">
                    <img
                      alt="image"
                      src="https://play.teleporthq.io/static/svg/default-img.svg"
                      className="home-image"
                    />
                  </div>
                  <img
                    alt="image"
                    src="/top-bar-img1-1500w.png"
                    className="home-image1"
                  />
                </div>
              </div>
            </div>
            <div className="home-container12">
              <span className="text">
                Designed to seamlessly integrate with the most popular CRMs
              </span>
              <div className="home-container13">
                <span>ClickUp</span>
                <span>HubSpot</span>
                <span>monday</span>
                <span>Freshsales</span>
                <span>salesforce</span>
                <span>Less Annoying CRM</span>
                <span>Zendesk</span>
                <span>Active Campaign</span>
              </div>
            </div>
          </div>
          <Component2></Component2>
          <div className="home-container14">
            <div className="home-container15">
              <span className="home-text12 titel">Our customers trust us</span>
              <span className="home-text13 text">
                <span>
                  Our tool is a game-changer for B2B SaaS companies, regardless
                  of their size or stage—whether they&apos;re just starting out
                  or scaling up.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  We offer use cases tailored to help every type of business
                  succeed.
                </span>
              </span>
            </div>
            <div className="home-container16">
              <a
                href="https://pyne.ai/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link"
              >
                <div className="home-container17">
                  <div className="home-container18">
                    <img
                      alt="image"
                      src="/Logo/pyne%20logo-200h.jpeg"
                      className="home-image2"
                    />
                  </div>
                  <div className="home-container19">
                    <span>pyne.ai</span>
                  </div>
                </div>
              </a>
              <a
                href="https://camsol.io/de/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1"
              >
                <div className="home-container20">
                  <div className="home-container21">
                    <img
                      alt="image"
                      src="/Logo/photo-2024-04-18-13-59-18-200h.jpg"
                      className="home-image3"
                    />
                  </div>
                  <div className="home-container22">
                    <span>camsol.io</span>
                  </div>
                </div>
              </a>
              <a
                href="https://codeshield.io/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link2"
              >
                <div className="home-container23">
                  <div className="home-container24">
                    <img
                      alt="image"
                      src="/Logo/photo-2024-04-18-14-00-12-200h.jpg"
                      className="home-image4"
                    />
                  </div>
                  <div className="home-container25">
                    <span>codeshield.io</span>
                  </div>
                </div>
              </a>
              <a
                href="https://gasvisor.eu/de/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link3"
              >
                <div className="home-container26">
                  <div className="home-container27">
                    <img
                      alt="image"
                      src="/Logo/photo-2024-04-18-14-04-00-200h.jpg"
                      className="home-image5"
                    />
                  </div>
                  <div className="home-container28">
                    <span>gasvisor.eu</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="home-container29">
            <div className="home-container30">
              <img
                alt="image"
                src="/Image/2c7af6cf-b4dc-4594-bd30-b6a9a2a7902c-500w.jpg"
                className="home-image6"
              />
            </div>
            <div className="home-container31">
              <div className="home-container32">
                <div className="home-container33">
                  <span className="sub-titel">- How to start</span>
                  <span className="titel">Simple 4-Step Process</span>
                </div>
                <span className="home-text23 text">
                  <span>We collaborate closely with our customers to</span>
                  <br></br>
                  <span>deliver optimal results.</span>
                </span>
              </div>
              <div className="home-container34">
                <div className="home-container35">
                  <div className="home-container36">
                    <svg viewBox="0 0 1024 1024" className="home-icon">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="home-container37">
                    <span className="text-2">
                      Initial call to discuss current needs
                    </span>
                  </div>
                </div>
                <div className="home-container38">
                  <div className="home-container39">
                    <svg viewBox="0 0 1024 1024" className="home-icon02">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="home-container40">
                    <span className="text-2">
                      Assessment of business model and opportunities
                    </span>
                  </div>
                </div>
                <div className="home-container41">
                  <div className="home-container42">
                    <svg viewBox="0 0 1024 1024" className="home-icon04">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="home-container43">
                    <span className="text-2">
                      Setting up and connecting to the current tool landscape
                    </span>
                  </div>
                </div>
                <div className="home-container44">
                  <div className="home-container45">
                    <svg viewBox="0 0 1024 1024" className="home-icon06">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="home-container46">
                    <span className="text-2">
                      Uncovering previously overlooked leads
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-container47">
            <SuccessStoriesComponent rootClassName="success-stories-component-root-class-name"></SuccessStoriesComponent>
          </div>
          <div className="home-container48">
            <div className="home-container49">
              <div className="home-container50">
                <div className="home-container51">
                  <span className="home-text31 sub-titel">- Inspiration</span>
                  <span className="home-text32 titel">
                    <span>Here are some use cases designed to boost your</span>
                    <br></br>
                    <span>growth through higher converting sales leads</span>
                  </span>
                </div>
                <Link to="/contact" className="home-navlink">
                  <div className="home-container52 box-shadow linear-gradient">
                    <span className="button-text">Get Started</span>
                  </div>
                </Link>
              </div>
              <div className="home-container53">
                <div className="home-container54">
                  <img
                    alt="image"
                    src="/Image/im1-400h.png"
                    className="home-image7"
                  />
                </div>
                <div className="home-container55">
                  <span className="home-text37 text">
                    In today&apos;s world, with countless tools available,
                    it&apos;s easy to lose focus. Often, the best approach is to
                    start from your current position and expand from there. We
                    specialize in helping you do just that, particularly if
                    you&apos;re a B2B SaaS company targeting SMEs. Our tool is
                    tailored specifically for your needs.
                  </span>
                  <Component1></Component1>
                </div>
              </div>
            </div>
          </div>
          <div className="home-container56">
            <div className="home-container57">
              <div className="home-container58">
                <span className="home-text38">- About us</span>
                <span className="home-text39">About Traffls</span>
              </div>
              <span className="home-text40">
                <span>
                  After thousands of Sales deals in different stages of
                  companies,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  our mindset and our tools help companies sales teams to grow
                  more scalable
                </span>
              </span>
              <div className="home-container59">
                <div className="home-container60">
                  <svg viewBox="0 0 1024 1024" className="home-icon08">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                </div>
                <div className="home-container61">
                  <span className="home-text44">Our Mission</span>
                  <span>
                    <span>
                      Getting the most out of your Sales department by
                    </span>
                    <br></br>
                    <span>having the right tools at the right time</span>
                  </span>
                </div>
              </div>
              <div className="home-container62">
                <div className="home-container63">
                  <svg viewBox="0 0 1024 1024" className="home-icon10">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                </div>
                <div className="home-container64">
                  <span className="home-text49">Our Vision</span>
                  <span>
                    <span>
                      Connecting the world of Sales to grow collaboratively in
                    </span>
                    <br></br>
                    <span>an environment where everyone benefits</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="home-container65">
              <img alt="image" src="/img_1.svg" className="home-image8" />
            </div>
          </div>
          <NewsletterComponent></NewsletterComponent>
          <BottomBar></BottomBar>
        </div>
      </div>
      <div className="home-container66"></div>
    </div>
  )
}

export default Home
