import React, { useState } from 'react';

import { Helmet } from 'react-helmet'

import TopBar from '../components/top-bar'
import SuccessStoriesComponent from '../components/success-stories-component'
import NewsletterComponent from '../components/newsletter-component'
import BottomBar from '../components/bottom-bar'
import './product.css'

const Product = (props) => {
  const [schedule, setSchedule] = useState(false)

  const handleButtonClick = () => {
    setSchedule(true);
  };
  return (
    <div className="product-container">
      <Helmet>
        <title>Product - Traffls</title>
        <meta property="og:title" content="Product - Traffls" />
      </Helmet>
      <div className="product-container01">
        <TopBar product={true} schedule={schedule}></TopBar>
      </div>
      <div className="product-container02">
        <div className="product-container03">
          <div className="product-container04">
            <div className="product-container05"></div>
            <div className="product-container06">
              <div className="product-container07">
                <div className="product-container08">
                  <span className="big-titel">
                    You might find gold within your CRM contacts - we are the
                    shovel
                  </span>
                </div>
                <span className="text">
                  20% of all employees change their job each year. So do also
                  the people that you closed before. Their new job is your new
                  opportunity
                </span>
                <div onClick={handleButtonClick} className="product-container09 box-shadow linear-gradient">
                  <span className="button-text">SCHEDULE A DEMO</span>
                </div>
              </div>
              <div className="product-container10">
                <div className="product-container11">
                  <img
                    alt="image"
                    src="https://play.teleporthq.io/static/svg/default-img.svg"
                    className="product-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="product-container12"></div>
          <div className="product-container13">
            <div className="product-container14">
              <span className="product-text03 titel">
                <span>We specialize in assisting B2B SaaS</span>
                <br></br>
                <span>businesses targeting SMEs.</span>
              </span>
            </div>
            <div className="product-container15">
              <div className="product-container16">
                <div className="product-container17">
                  <img
                    alt="image"
                    src="/product-img/im2-300h.png"
                    className="product-image1"
                  />
                </div>
                <div className="product-container18">
                  <span className="titel-small">
                    <span>
                      Connect your sales systems to ours, and let our AI uncover
                      highly probable
                    </span>
                    <br></br>
                    <span>new leads for you.</span>
                  </span>
                  <span>
                    <span>
                      In the SME segment, finding high-probability leads can be
                      tough, leading to wasted sales team efforts. Our tool
                      saves time while delivering more deals simultaneously.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="product-container19">
                <div className="product-container20">
                  <img
                    alt="image"
                    src="/Image/img6-500w.png"
                    loading="eager"
                    className="product-image2"
                  />
                </div>
                <div className="product-container21">
                  <span className="titel-small">
                    After the setup, we&apos;ll deliver the leads you desire
                    directly into your CRM.
                  </span>
                  <span>
                    If you prefer not to switch to another tool, we&apos;ve got
                    you covered. With our seamless integration, you can easily
                    stay within your existing systems, ensuring a smooth
                    transition without disrupting your workflow.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="product-container22">
            <div className="product-container23">
              <div className="product-container24">
                <span className="product-text16 sub-titel">
                  - Better results
                </span>
                <span className="product-text17 titel">
                  Having worked with every sales tool out there, we guarantee
                  that our solution outshines the rest, delivering unparalleled
                  results every time.
                </span>
              </div>
              <div className="product-container25">
                <div className="product-container26">
                  <div className="product-container27">
                    <svg viewBox="0 0 1024 1024" className="product-icon">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="product-container28">
                    <span className="titel-2">Understanding your needs</span>
                  </div>
                </div>
                <div className="product-container29">
                  <div className="product-container30">
                    <svg viewBox="0 0 1024 1024" className="product-icon02">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="product-container31">
                    <span className="titel-2">24/7 Lead Delivery</span>
                  </div>
                </div>
                <div className="product-container32">
                  <div className="product-container33">
                    <svg viewBox="0 0 1024 1024" className="product-icon04">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="product-container34">
                    <span className="titel-2">Highest-in-class CVR</span>
                  </div>
                </div>
                <div className="product-container35">
                  <div className="product-container36">
                    <svg viewBox="0 0 1024 1024" className="product-icon06">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="product-container37">
                    <span className="titel-2">Continuous Learning</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-container38">
              <img
                alt="image"
                src="/Image/im5-500h.png"
                loading="eager"
                className="product-image3"
              />
            </div>
          </div>
          <div className="product-container39">
            <SuccessStoriesComponent></SuccessStoriesComponent>
          </div>
          <div className="product-container40">
            <div className="product-container41">
              <img
                alt="image"
                src="/Image/im1-500w.png"
                loading="eager"
                className="product-image4"
              />
            </div>
            <div className="product-container42">
              <div className="product-container43">
                <span className="product-text22 sub-titel">
                  - Our AI learns with you
                </span>
                <span className="product-text23 titel">
                  The longer you partner with us, the higher the quality of
                  leads you&apos;ll receive. It&apos;s a journey towards
                  ever-improving success!
                </span>
              </div>
              <div className="product-container44">
                <div className="product-container45">
                  <div className="product-container46">
                    <div className="product-container47">
                      <svg viewBox="0 0 1024 1024" className="product-icon08">
                        <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                      </svg>
                    </div>
                    <div className="product-container48">
                      <span className="text-2 titel-2">
                        Our database is continuously expanding
                      </span>
                    </div>
                  </div>
                  <div className="product-container49">
                    <div className="product-container50"></div>
                    <div className="product-container51">
                      <span className="text">
                        We&apos;re dedicated to constantly sourcing more data to
                        enhance our predictive capabilities for your benefit
                      </span>
                    </div>
                  </div>
                </div>
                <div className="product-container52">
                  <div className="product-container53">
                    <div className="product-container54">
                      <svg viewBox="0 0 1024 1024" className="product-icon10">
                        <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                      </svg>
                    </div>
                    <div className="product-container55">
                      <span className="titel-2">
                        <span>Your data is continuously growing</span>
                        <br></br>
                      </span>
                    </div>
                  </div>
                  <div className="product-container56">
                    <div className="product-container57"></div>
                    <div className="product-container58">
                      <span>
                        The more data we analyze from you, the more precise our
                        predictions become, ensuring your sales success.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="product-container59">
                  <div className="product-container60">
                    <div className="product-container61">
                      <svg viewBox="0 0 1024 1024" className="product-icon12">
                        <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                      </svg>
                    </div>
                    <div className="product-container62">
                      <span className="titel-small titel-2">
                        Our partner network is constantly strengthening
                      </span>
                    </div>
                  </div>
                  <div className="product-container63">
                    <div className="product-container64"></div>
                    <div className="product-container65">
                      <span>
                        With more partners comes a deeper understanding of the
                        market and increased potential for each individual
                        partner to thrive.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NewsletterComponent rootClassName="newsletter-component-root-class-name"></NewsletterComponent>
          <BottomBar></BottomBar>
        </div>
      </div>
    </div>
  )
}

export default Product
