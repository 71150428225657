import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './contact-request-component.css'

const ContactRequestComponent = (props) => {
  const [request, setRequest] = useState(false)
  return (
    <div
      className={`contact-request-component-container ${props.rootClassName} `}
    >
      <div className="contact-request-component-container1">
        <input
          type="text"
          placeholder="Full name"
          className="contact-request-component-textinput"
        />
      </div>
      <div className="contact-request-component-container2">
        <input
          type="text"
          placeholder="E-Mail address"
          className="contact-request-component-textinput1"
        />
      </div>
      <div className="contact-request-component-container3">
        <input
          type="text"
          placeholder="Phone number"
          className="contact-request-component-textinput2"
        />
      </div>
      <div className="contact-request-component-container4">
        <textarea
          placeholder="Type your message here..."
          className="contact-request-component-textarea"
        ></textarea>
      </div>
      {!request && (
        <div className="contact-request-component-container5 box-shadow linear-gradient">
          <span className="">Send message</span>
        </div>
      )}
      {request && (
        <div className="contact-request-component-container6">
          <span className="text">Contact request sent.</span>
        </div>
      )}
    </div>
  )
}

ContactRequestComponent.defaultProps = {
  rootClassName: '',
}

ContactRequestComponent.propTypes = {
  rootClassName: PropTypes.string,
}

export default ContactRequestComponent
