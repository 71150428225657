import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './price-item.css'

const PriceItem = (props) => {
  const [infoBox, setInfoBox] = useState(false)
  const [titel, setTitel] = useState(props.titel)
  const [info, setInfo] = useState(props.info)

  return (
    <div className="price-item-price-item">
      <span className="text">{props.titel}</span>
      <svg
        viewBox="0 0 877.7142857142857 1024"
        onMouseEnter={() => setInfoBox(true)}
        onMouseLeave={() => setInfoBox(false)}
        className="price-item-icon"
      >
        <path d="M512 786.286v-109.714c0-10.286-8-18.286-18.286-18.286h-109.714c-10.286 0-18.286 8-18.286 18.286v109.714c0 10.286 8 18.286 18.286 18.286h109.714c10.286 0 18.286-8 18.286-18.286zM658.286 402.286c0-104.571-109.714-182.857-208-182.857-93.143 0-162.857 40-212 121.714-5.143 8-2.857 18.286 4.571 24l75.429 57.143c2.857 2.286 6.857 3.429 10.857 3.429 5.143 0 10.857-2.286 14.286-6.857 26.857-34.286 38.286-44.571 49.143-52.571 9.714-6.857 28.571-13.714 49.143-13.714 36.571 0 70.286 23.429 70.286 48.571 0 29.714-15.429 44.571-50.286 60.571-40.571 18.286-96 65.714-96 121.143v20.571c0 10.286 8 18.286 18.286 18.286h109.714c10.286 0 18.286-8 18.286-18.286v0c0-13.143 16.571-41.143 43.429-56.571 43.429-24.571 102.857-57.714 102.857-144.571zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
      </svg>
      {infoBox && (
        <div
          onMouseLeave={() => setInfoBox(false)}
          className="price-item-container"
        >
          <span className="price-item-text1 text">{props.info}</span>
        </div>
      )}
    </div>
  )
}

PriceItem.defaultProps = {
  info: 'We collaborate closely with our customers todeliver optimal results.',
  name: 'Contact Uploads',
}

PriceItem.propTypes = {
  info: PropTypes.string,
  name: PropTypes.string,
}

export default PriceItem
