import React from 'react'

import { Helmet } from 'react-helmet'

import TopBar from '../components/top-bar'
import NewsletterComponent from '../components/newsletter-component'
import BottomBar from '../components/bottom-bar'
import './imprint.css'

const Imprint = (props) => {
  return (
    <div className="imprint-container">
      <Helmet>
        <title>Imprint - Traffls</title>
        <meta property="og:title" content="Imprint - Traffls" />
      </Helmet>
      <div className="imprint-container01">
        <TopBar></TopBar>
      </div>
      <div className="imprint-container02">
        <div className="imprint-container03">
          <div className="imprint-container04">
            <div className="imprint-container05">
              <div className="imprint-container06">
                <div className="imprint-container07">
                  <span className="big-titel">
                    Guarding Your Information: Unveiling Our Privacy Policy
                  </span>
                </div>
                <span className="text">
                  <span>Empowering Your Data Control:</span>
                  <br></br>
                  <span>
                    A Thorough Examination of Our Privacy Policy and How It Puts
                    You in Charge
                  </span>
                </span>
                <div className="imprint-container08 box-shadow linear-gradient">
                  <span className="button-text">SCHEDULE A DEMO</span>
                </div>
              </div>
              <div className="imprint-container09">
                <div className="imprint-container10">
                  <img
                    alt="image"
                    src="https://play.teleporthq.io/static/svg/default-img.svg"
                    className="imprint-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="imprint-container11"></div>
          <div className="imprint-container12">
            <span className="imprint-text06">
              <span>Impressum</span>
              <br></br>
            </span>
            <span className="imprint-text09">Angaben gemäß § 5 TMG</span>
            <div className="imprint-container13">
              <span className="imprint-text10">
                <span>Up2data GmbH</span>
                <br></br>
                <span>Prinz-Ludwig-Straße 4</span>
                <br></br>
                <span>80333 München</span>
              </span>
              <span className="imprint-text16">
                <span>Handelsregister: HRB284016</span>
                <br></br>
                <span>Registergericht: München</span>
              </span>
              <span className="imprint-text20">
                <span>Vertreten durch:</span>
                <br></br>
                <span>Daniel Donhauser</span>
              </span>
            </div>
            <span className="imprint-text24">Kontakt</span>
            <div className="imprint-container14">
              <span className="imprint-text25">E-Mail: info@traffls.com</span>
            </div>
            <span className="imprint-text26">
              Verbraucherstreitbeilegung/Universalschlichtungsstelle
            </span>
            <span className="imprint-text27">
              <span>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
              </span>
              <br></br>
              <span>Verbraucherschlichtungsstelle teilzunehmen.</span>
              <br></br>
            </span>
          </div>
          <NewsletterComponent rootClassName="newsletter-component-root-class-name7"></NewsletterComponent>
          <BottomBar></BottomBar>
        </div>
      </div>
    </div>
  )
}

export default Imprint
