import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './newsletter-component.css'

const NewsletterComponent = (props) => {
  const [newsletterSend, setNewsletterSend] = useState(false)
  return (
    <div className={`newsletter-component-container ${props.rootClassName} `}>
      <div className="newsletter-component-container1">
        <span className="newsletter-component-text titel">
          Subscribe to Our Newsletter
        </span>
        <span className="newsletter-component-text1 text">
          Enter your details to receive business inspiration, trending
          solutions, and expert consulting tips straight to your inbox
        </span>
      </div>
      {!newsletterSend && (
        <div className="newsletter-component-container2">
          <input
            type="text"
            placeholder="Enter your email address"
            className="newsletter-component-textinput"
          />
          <div className="newsletter-component-container3 linear-gradient">
            <svg viewBox="0 0 1024 1024" className="newsletter-component-icon">
              <path
                d="M128 337.963l359.552 251.691c14.507 10.027 33.92 10.496 48.939 0l359.509-251.691v430.037c0 11.605-4.693 22.229-12.587 30.080s-18.475 12.587-30.080 12.587h-682.667c-11.605 0-22.229-4.693-30.080-12.587s-12.587-18.475-12.587-30.080zM42.667 256.512v511.488c0 35.328 14.507 67.371 37.547 90.453s55.125 37.547 90.453 37.547h682.667c35.328 0 67.371-14.507 90.453-37.547s37.547-55.125 37.547-90.453v-511.488c0-0.427 0-0.853 0-1.28-0.213-35.029-14.635-66.773-37.547-89.685-23.083-23.040-55.125-37.547-90.453-37.547h-682.667c-35.328 0-67.371 14.507-90.453 37.547-22.912 22.912-37.333 54.656-37.547 89.728 0 0.213 0 0.469 0 0.725zM891.477 236.971l-379.477 265.6-379.477-265.6c2.048-4.096 4.779-7.808 8.021-11.051 7.893-7.893 18.517-12.587 30.123-12.587h682.667c11.605 0 22.229 4.693 30.080 12.587 3.243 3.243 5.973 6.997 8.021 11.051z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
      {newsletterSend && (
        <div className="newsletter-component-container4">
          <span className="text">Thank you for choosing our newslatter.</span>
        </div>
      )}
    </div>
  )
}

NewsletterComponent.defaultProps = {
  rootClassName: '',
}

NewsletterComponent.propTypes = {
  rootClassName: PropTypes.string,
}

export default NewsletterComponent
