import React, { useState } from 'react'

import './component2.css'

const Component2 = (props) => {
  const [icon4, setIcon4] = useState(false)
  const [icon2, setIcon2] = useState(false)
  const [icon5, setIcon5] = useState(false)
  const [icon6, setIcon6] = useState(false)
  const [icon3, setIcon3] = useState(false)
  const [icon1, setIcon1] = useState(false)
  return (
    <div className="component2-container">
      <div className="component2-container01">
        <span className="titel">
          <span>Leverage our AI technology alongside your data to</span>
          <br></br>
          <span>catapult your company into the era of AI-driven sales.</span>
        </span>
        <span className="component2-text04 text">
          Often, sales teams miss prime opportunities due to time constraints
          and lack of experience, defaulting to familiar structures and
          processes instead. We streamline their workflow, enabling them to use
          their time more effectively and secure more deals for your business.
        </span>
      </div>
      <div className="component2-container02">
        <div className="component2-container03">
          <div className="component2-container04">
            <img
              alt="image"
              src="/Icons/calendar1-200h.png"
              className="component2-image"
            />
          </div>
          <div className="component2-container05">
            <span className="titel-small">
              <span>Surge of high-converting</span>
              <br></br>
              <span>deals</span>
            </span>
            <span className="text">
              We enhance your existing customer base analysis for potential new
              deals using AI-powered sales insights.
            </span>
          </div>
        </div>
        <div className="component2-container06">
          <div className="component2-container07">
            <img
              alt="image"
              src="/Icons/gear1-200h.png"
              className="component2-image01"
            />
          </div>
          <div className="component2-container08">
            <span className="titel-small">
              <span>Only initial setup</span>
              <br></br>
              <span>necessary</span>
            </span>
            <span className="text">
              Once everything is set up, we&apos;ll seamlessly integrate
              high-converting leads directly into your systems.
            </span>
          </div>
        </div>
        <div className="component2-container09">
          <div className="component2-container10">
            <img
              alt="image"
              src="/Icons/storage1-200h.png"
              className="component2-image02"
            />
          </div>
          <div className="component2-container11">
            <span className="titel-small">
              We operate seamlessly in the background
            </span>
            <span className="text">
              We understand the hassle of switching between tools, so we ensure
              our service operates smoothly in the background.
            </span>
          </div>
        </div>
      </div>
      <div className="component2-container12">
        <div className="component2-container13">
          <div className="component2-container14">
            <img
              alt="image"
              src="/Icons/split-200h.png"
              className="component2-image03"
            />
          </div>
          <div className="component2-container15">
            <span className="titel-small">
              Integrates with most the most popular CRMs
            </span>
            <span className="text">
              <span>
                If you&apos;re using a standard CRM solution, we guarantee
                seamless compatibility and functionality.
              </span>
              <br></br>
            </span>
          </div>
        </div>
        <div className="component2-container16">
          <div className="component2-container17">
            <img
              alt="image"
              src="/Icons/roi-200h.png"
              className="component2-image04"
            />
          </div>
          <div className="component2-container18">
            <span className="titel-small">
              Achieve superior ROI with our strategies
            </span>
            <span className="text">
              <span>Your sales reps have limited time;</span>
              <br></br>
              <span> we help them focus on the most lucrative leads.</span>
            </span>
          </div>
        </div>
        <div className="component2-container19">
          <div className="component2-container20">
            <img
              alt="image"
              src="/Icons/generator-200h.png"
              className="component2-image05"
            />
          </div>
          <div className="component2-container21">
            <span className="titel-small">
              Automated Lead Generation and Enrichment System
            </span>
            <span className="text">
              <span>
                An additional advantage is
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                that we keep your CRM up-to-date
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>
                like it should be
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="component2-container22">
        <div className="component2-container23">
          <div
            onMouseOver={() => setIcon1(true)}
            onMouseLeave={() => setIcon1(false)}
            className="component2-container24"
          >
            <div className="component2-container25">
              {!icon1 && (
                <div className="component2-container26">
                  <img
                    alt="image"
                    src="/Icons/calendar1-200h.png"
                    className="component2-image06"
                  />
                </div>
              )}
              {icon1 && (
                <div className="component2-container27">
                  <img
                    alt="image"
                    src="/Icons/calendar1-200h.png"
                    className="component2-image07"
                  />
                </div>
              )}
            </div>
            <div className="component2-container28">
              <span className="titel-small">
                <span>Surge of high-converting</span>
                <br></br>
                <span>deals</span>
              </span>
              <span className="component2-text37 text">
                We enhance your existing customer base analysis for potential
                new deals using AI-powered sales insights.
              </span>
            </div>
          </div>
          <div
            onMouseOver={() => setIcon2(true)}
            onMouseLeave={() => setIcon2(false)}
            className="component2-container29"
          >
            <div className="component2-container30">
              {!icon2 && (
                <div className="component2-container31">
                  <img
                    alt="image"
                    src="/Icons/gear1-200h.png"
                    className="component2-image08"
                  />
                </div>
              )}
              {icon2 && (
                <div className="component2-container32">
                  <img
                    alt="image"
                    src="/Icons/gear1-200h.png"
                    className="component2-image09"
                  />
                </div>
              )}
            </div>
            <div className="component2-container33">
              <span className="titel-small">
                <span>Only initial setup</span>
                <br></br>
                <span>necessary</span>
              </span>
              <span className="component2-text42 text">
                Once everything is set up, we&apos;ll seamlessly integrate
                high-converting leads directly into your systems.
              </span>
            </div>
          </div>
          <div
            onMouseOver={() => setIcon3(true)}
            onMouseLeave={() => setIcon3(false)}
            className="component2-container34"
          >
            <div className="component2-container35">
              {!icon3 && (
                <div className="component2-container36">
                  <img
                    alt="image"
                    src="/Icons/storage1-200h.png"
                    className="component2-image10"
                  />
                </div>
              )}
              {icon3 && (
                <div className="component2-container37">
                  <img
                    alt="image"
                    src="/Icons/storage1-200h.png"
                    className="component2-image11"
                  />
                </div>
              )}
            </div>
            <div className="component2-container38">
              <span className="titel-small">
                We operate seamlessly in the background
              </span>
              <span className="component2-text44 text">
                We understand the hassle of switching between tools, so we
                ensure our service operates smoothly in the background.
              </span>
            </div>
          </div>
        </div>
        <div className="component2-container39">
          <div
            onMouseOver={() => setIcon4(true)}
            onMouseLeave={() => setIcon4(false)}
            className="component2-container40"
          >
            <div className="component2-container41">
              {!icon4 && (
                <div className="component2-container42">
                  <img
                    alt="image"
                    src="/Icons/split-200h.png"
                    className="component2-image12"
                  />
                </div>
              )}
              {icon4 && (
                <div className="component2-container43">
                  <img
                    alt="image"
                    src="/Icons/split-200h.png"
                    className="component2-image13"
                  />
                </div>
              )}
            </div>
            <div className="component2-container44">
              <span className="titel-small">
                Integrates with most the most popular CRMs
              </span>
              <span className="component2-text46 text">
                <span>
                  If you&apos;re using a standard CRM solution, we guarantee
                  seamless compatibility and functionality.
                </span>
                <br></br>
              </span>
            </div>
          </div>
          <div
            onMouseOver={() => setIcon5(true)}
            onMouseLeave={() => setIcon5(false)}
            className="component2-container45"
          >
            <div className="component2-container46">
              {!icon5 && (
                <div className="component2-container47">
                  <img
                    alt="image"
                    src="/Icons/roi-200h.png"
                    className="component2-image14"
                  />
                </div>
              )}
              {icon5 && (
                <div className="component2-container48">
                  <img
                    alt="image"
                    src="/Icons/roi-200h.png"
                    className="component2-image15"
                  />
                </div>
              )}
            </div>
            <div className="component2-container49">
              <span className="titel-small">
                Achieve superior ROI with our strategies
              </span>
              <span className="component2-text50 text">
                <span>Your sales reps have limited time;</span>
                <br></br>
                <span> we help them focus on the most lucrative leads.</span>
              </span>
            </div>
          </div>
          <div
            onMouseOver={() => setIcon6(true)}
            onMouseLeave={() => setIcon6(false)}
            className="component2-container50"
          >
            <div className="component2-container51">
              {!icon6 && (
                <div className="component2-container52">
                  <img
                    alt="image"
                    src="/Icons/generator-200h.png"
                    className="component2-image16"
                  />
                </div>
              )}
              {icon6 && (
                <div className="component2-container53">
                  <img
                    alt="image"
                    src="/Icons/generator-200h.png"
                    className="component2-image17"
                  />
                </div>
              )}
            </div>
            <div className="component2-container54">
              <span className="titel-small">
                Automated Lead Generation and Enrichment System
              </span>
              <span className="component2-text55 text">
                <span>
                  An additional advantage is
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  that we keep your CRM up-to-date
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  like it should be
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component2
