import React from 'react'

import { Helmet } from 'react-helmet'

import TopBar from '../components/top-bar'
import ContactRequestComponent from '../components/contact-request-component'
import NewsletterComponent from '../components/newsletter-component'
import BottomBar from '../components/bottom-bar'
import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Traffls</title>
        <meta property="og:title" content="Contact - Traffls" />
      </Helmet>
      <div className="contact-container01">
        <TopBar contact={true} ></TopBar>
      </div>
      <div className="contact-container02">
        <div className="contact-container03">
          <div className="contact-container04">
            <div className="contact-container05">
              <div className="contact-container06">
                <div className="contact-container07">
                  <span className="big-titel">
                    We&apos;re thrilled to dive into discussions about your
                    growth trajectory!
                  </span>
                </div>
                <span className="text">
                  Embark on a transformative journey into a new era of sales
                  with us as your trusted partner by your side. Together,
                  we&apos;ll chart the course to unprecedented success and
                  growth
                </span>
              </div>
              <div className="contact-container08">
                <div className="contact-container09">
                  <img
                    alt="image"
                    src="https://play.teleporthq.io/static/svg/default-img.svg"
                    className="contact-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="contact-container10"></div>
          <div className="contact-container11">
            <div className="contact-container12">
              <div className="contact-container13">
                <span className="titel">Get In Touch With Us</span>
              </div>
              <span className="contact-text3 text">
                Discover the potential of data with Traffls! Our innovative
                solution uncovers hidden sales leads, providing your team with
                new opportunities. Let us help you find high-conversion leads
                effortlessly. Reach out to us to learn more!
              </span>
              <div className="contact-container14">
                <div className="contact-container15">
                  <svg viewBox="0 0 1024 1024" className="contact-icon">
                    <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                  </svg>
                </div>
                <div className="contact-container16">
                  <span className="text">E-Mail address:</span>
                  <a href="mailto:info@up2data.io?subject=" className="titel-3">
                    info@traffls.com
                  </a>
                </div>
              </div>
            </div>
            <ContactRequestComponent></ContactRequestComponent>
          </div>
          <NewsletterComponent rootClassName="newsletter-component-root-class-name2"></NewsletterComponent>
          <BottomBar></BottomBar>
        </div>
      </div>
    </div>
  )
}

export default Contact
