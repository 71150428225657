import React, { useState } from 'react';

import { Helmet } from 'react-helmet'

import TopBar from '../components/top-bar'
import PriceItem from '../components/price-item'
import NewsletterComponent from '../components/newsletter-component'
import BottomBar from '../components/bottom-bar'
import './pricing.css'

const Pricing = (props) => {
  const [schedule, setSchedule] = useState(false)

  const handleButtonClick = () => {
    setSchedule(true);
  };
  return (
    <div className="pricing-container">
      <Helmet>
        <title>Pricing - Traffls</title>
        <meta property="og:title" content="Pricing - Traffls" />
      </Helmet>
      <div className="pricing-container01">
        <TopBar pricing={true} schedule={schedule}></TopBar>
      </div>
      <div className="pricing-container02">
        <div className="pricing-container03">
          <div className="pricing-container04">
            <div className="pricing-container05"></div>
            <div className="pricing-container06">
              <div className="pricing-container07">
                <div className="pricing-container08">
                  <span className="big-titel">
                    <span>Our </span>
                    <span>Pricing </span>
                    <span>Plans</span>
                  </span>
                </div>
                <span className="text">
                  Unleash the power of data with traffls! Our innovative company
                  uncovers hidden sales leads that your team never even
                  considered. Think outside the box and let AI reveal new
                  opportunities for your business. Get high-conversion leads
                  with ease and watch your sales soar!
                </span>
                <div onClick={handleButtonClick} className="pricing-container09 box-shadow linear-gradient">
                  <span className="button-text">SCHEDULE A DEMO</span>
                </div>
              </div>
              <div className="pricing-container10">
                <div className="pricing-container11">
                  <img
                    alt="image"
                    src="https://play.teleporthq.io/static/svg/default-img.svg"
                    className="pricing-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pricing-container12"></div>
          <div className="pricing-container13">
            <div className="pricing-container14 box-shadow">
              <div className="pricing-container15">
                <div className="pricing-container16">
                  <span className="pricing-text06 titel">
                    <span>Choose</span>
                    <br></br>
                    <span>your plan</span>
                    <br></br>
                  </span>
                </div>
                <div className="pricing-container17">
                  <div className="pricing-container18">
                    <span className="titel-3">Top features</span>
                  </div>
                  <PriceItem titel="Contact Uploads"></PriceItem>
                  <PriceItem titel="Monthly traffls credits"></PriceItem>
                  <PriceItem titel="Monthly Scan"></PriceItem>
                  <div className="pricing-container21">
                    <span className="titel-3">Product capabilities</span>
                  </div>
                  <PriceItem titel="Email Notification"></PriceItem>
                  <PriceItem titel="CRM Integration"></PriceItem>
                  <PriceItem titel="Partnerships"></PriceItem>
                </div>
              </div>
              <div className="pricing-container25">
                <div className="pricing-container26">
                  <span className="titel-3">
                    <span>Free</span>
                    <br></br>
                  </span>
                  <div className="pricing-container27">
                    <div className="pricing-container28">
                      <span className="titel">0€</span>
                      <span className="pricing-text22">/</span>
                      <span className="pricing-text23">mo</span>
                    </div>
                    <span className="pricing-text24">
                      <span>Available for 3 months,</span>
                      <br></br>
                      <span>upgrade required thereafter</span>
                    </span>
                  </div>
                  <a
                    href="https://dashboard.traffls.com/"
                    className="pricing-link"
                  >
                    <div className="pricing-container29 linear-gradient">
                      <span className="button-text">Order now</span>
                    </div>
                  </a>
                </div>
                <div className="pricing-container30">
                  <div className="pricing-container31"></div>
                  <div className="pricing-container32">
                    <span className="text">25 Monthly</span>
                  </div>
                  <div className="pricing-container33">
                    <span className="text">0</span>
                  </div>
                  <div className="pricing-container34">
                    <span className="text">1</span>
                  </div>
                  <div className="pricing-container35"></div>
                  <div className="pricing-container36">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="pricing-container37">
                    <span className="pricing-text32">-</span>
                  </div>
                  <div className="pricing-container38">
                    <span className="pricing-text33">-</span>
                  </div>
                </div>
              </div>
              <div className="pricing-container39">
                <div className="pricing-container40">
                  <span className="pricing-text34 titel-3">
                    <span>Small</span>
                    <br></br>
                  </span>
                  <div className="pricing-container41">
                    <div className="pricing-container42">
                      <span className="titel">25€</span>
                      <span className="pricing-text38">/</span>
                      <span className="pricing-text39">mo</span>
                    </div>
                    <span className="pricing-text40">
                      <span>billed yearly</span>
                      <br></br>
                      <span>or 35€ billed monthly</span>
                      <br></br>
                    </span>
                  </div>
                  <a
                    href="https://dashboard.traffls.com/"
                    className="pricing-link1"
                  >
                    <div className="pricing-container43 linear-gradient">
                      <span className="button-text">Order now</span>
                    </div>
                  </a>
                </div>
                <div className="pricing-container44">
                  <div className="pricing-container45"></div>
                  <div className="pricing-container46">
                    <span className="text">100 Monthly</span>
                  </div>
                  <div className="pricing-container47">
                    <span className="text">5</span>
                  </div>
                  <div className="pricing-container48">
                    <span className="text">1</span>
                  </div>
                  <div className="pricing-container49"></div>
                  <div className="pricing-container50">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon02">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="pricing-container51">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon04">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="pricing-container52">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon06">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="pricing-container53">
                <div className="pricing-container54">
                  <span className="titel-3">
                    <span>Medium</span>
                    <br></br>
                  </span>
                  <div className="pricing-container55">
                    <div className="pricing-container56">
                      <span className="titel">125€</span>
                      <span className="pricing-text53">/</span>
                      <span className="pricing-text54">mo</span>
                    </div>
                    <span className="pricing-text55">
                      <span>billed yearly</span>
                      <br></br>
                      <span>or 135€ billed monthly</span>
                      <br></br>
                    </span>
                  </div>
                  <a
                    href="https://dashboard.traffls.com/"
                    className="pricing-link2"
                  >
                    <div className="pricing-container57 linear-gradient">
                      <span className="button-text">Order now</span>
                    </div>
                  </a>
                </div>
                <div className="pricing-container58">
                  <div className="pricing-container59"></div>
                  <div className="pricing-container60">
                    <span className="text">1000 Monthly</span>
                  </div>
                  <div className="pricing-container61">
                    <span className="text">27</span>
                  </div>
                  <div className="pricing-container62">
                    <span className="text">2</span>
                  </div>
                  <div className="pricing-container63"></div>
                  <div className="pricing-container64">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon08">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="pricing-container65">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon10">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="pricing-container66">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon12">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="pricing-container67">
                <div className="pricing-container68">
                  <span className="titel-3">
                    <span>Big</span>
                    <br></br>
                  </span>
                  <div className="pricing-container69">
                    <div className="pricing-container70">
                      <span className="titel">--€</span>
                      <span className="pricing-text68">/</span>
                      <span className="pricing-text69">mo</span>
                    </div>
                    <span className="pricing-text70">
                      <span>A  </span>
                      <span>request</span>
                      <br></br>
                      <span>is necessary</span>
                    </span>
                  </div>
                  <a
                    href="https://dashboard.traffls.com/"
                    className="pricing-link3"
                  >
                    <div className="pricing-container71 linear-gradient">
                      <span className="button-text">Order now</span>
                    </div>
                  </a>
                </div>
                <div className="pricing-container72">
                  <div className="pricing-container73"></div>
                  <div className="pricing-container74">
                    <span className="text">Unlimited</span>
                  </div>
                  <div className="pricing-container75">
                    <span className="text">200</span>
                  </div>
                  <div className="pricing-container76">
                    <span className="text">Daily</span>
                  </div>
                  <div className="pricing-container77"></div>
                  <div className="pricing-container78">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon14">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="pricing-container79">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon16">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                  <div className="pricing-container80">
                    <svg viewBox="0 0 1024 1024" className="pricing-icon18">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NewsletterComponent rootClassName="newsletter-component-root-class-name4"></NewsletterComponent>
          <BottomBar></BottomBar>
        </div>
      </div>
    </div>
  )
}

export default Pricing
