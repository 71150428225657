import React, { useState } from 'react';

import { Helmet } from 'react-helmet'

import TopBar from '../components/top-bar'
import SuccessStoriesComponent from '../components/success-stories-component'
import NewsletterComponent from '../components/newsletter-component'
import BottomBar from '../components/bottom-bar'
import './about-us.css'

const AboutUs = (props) => {
  const [schedule, setSchedule] = useState(false)

  const handleButtonClick = () => {
    setSchedule(true);
  };
  return (
    <div className="about-us-container">
      <Helmet>
        <title>About-us - Traffls</title>
        <meta property="og:title" content="About-us - Traffls" />
      </Helmet>
      <div className="about-us-container01">
        <TopBar aboutUs={true} schedule={schedule}></TopBar>
      </div>
      <div className="about-us-container02">
        <div className="about-us-container03">
          <div className="about-us-container04">
            <div className="about-us-container05">
              <div className="about-us-container06">
                <div className="about-us-container07">
                  <span className="big-titel">
                    We approach sales with a unique perspective
                  </span>
                </div>
                <span className="text">
                  Our tools are designed to empower sales teams of B2B SaaS
                  companies, from small startups to large scaleups. We
                  understand that processes can always be optimized, and
                  we&apos;re here to guide you every step of the way
                </span>
                <div onClick={handleButtonClick} className="about-us-container08 box-shadow linear-gradient">
                  <span className="button-text">SCHEDULE A DEMO</span>
                </div>
              </div>
              <div className="about-us-container09">
                <div className="about-us-container10">
                  <img
                    alt="image"
                    src="https://play.teleporthq.io/static/svg/default-img.svg"
                    className="about-us-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="about-us-container11"></div>
          <div className="about-us-container12">
            <div className="about-us-container13">
              <div className="about-us-container14">
                <span className="about-us-text03 sub-titel">- About us</span>
                <span className="titel">About Traffls</span>
              </div>
              <span className="about-us-text05 text">
                We are a visionary team of highly motivated Sales and Tech
                experts, with a wealth of experience in various B2B Sales
                Structures and Approaches. Drawing from our collective
                knowledge, we&apos;re dedicated to crafting cutting-edge
                software that empowers sales teams to scale with unprecedented
                efficiency. Our mission is to astonish our customers by
                delivering innovations that redefine possibilities. We strive to
                offer tools that surpass existing options in both quality and
                affordability, setting new standards in the market.
              </span>
            </div>
            <div className="about-us-container15">
              <img
                alt="image"
                src="/Image/im4-300h.png"
                className="about-us-image1"
              />
            </div>
          </div>
          <div className="about-us-container16">
            <div className="about-us-container17">
              <img
                alt="image"
                src="/group%201413372149-500h.png"
                className="about-us-image2"
              />
            </div>
            <div className="about-us-container18">
              <div className="about-us-container19">
                <span className="about-us-text06 sub-titel">- Our Mission</span>
                <span className="titel">Our Mission to enable success</span>
              </div>
              <span className="about-us-text08 text">
                As our partnership grows and we gain insights into what works
                best for you, we deliver top-tier leads. Our aim is to build a
                network of hyper-successful B2B SaaS companies who credit their
                success to our collaboration
              </span>
            </div>
          </div>
          <div className="about-us-container20">
            <SuccessStoriesComponent rootClassName="success-stories-component-root-class-name1"></SuccessStoriesComponent>
          </div>
          <div className="about-us-container21">
            <div className="about-us-container22">
              <div className="about-us-container23">
                <span className="about-us-text09 sub-titel">- Our Vision</span>
                <span className="about-us-text10 titel">
                  <span>Our Vision to change</span>
                  <br></br>
                  <span>Sales in the future</span>
                </span>
              </div>
              <span className="about-us-text14 text">
                We envision a future where the sales landscape undergoes a
                profound transformation, becoming an entirely different game. We
                are committed to actively shaping this evolution. In this
                future, understanding the unique needs of individuals will be
                paramount before initiating contact. This shift will minimize
                efforts by moving away from mass outbound activities, and in
                turn, increase the likelihood of closing deals as individuals
                won&apos;t feel pressured by direct sales tactics.
              </span>
            </div>
            <div className="about-us-container24">
              <img alt="image" src="/img_2.svg" className="about-us-image3" />
            </div>
          </div>
          <NewsletterComponent rootClassName="newsletter-component-root-class-name1"></NewsletterComponent>
          <BottomBar></BottomBar>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
