import React from 'react'

import { Helmet } from 'react-helmet'

import TopBar from '../components/top-bar'
import NewsletterComponent from '../components/newsletter-component'
import BottomBar from '../components/bottom-bar'
import './privacy-policy.css'

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Privacy-Policy - Traffls</title>
        <meta property="og:title" content="Privacy-Policy - Traffls" />
      </Helmet>
      <div className="privacy-policy-container01">
        <TopBar></TopBar>
      </div>
      <div className="privacy-policy-container02">
        <div className="privacy-policy-container03">
          <div className="privacy-policy-container04">
            <div className="privacy-policy-container05">
              <div className="privacy-policy-container06">
                <div className="privacy-policy-container07">
                  <span className="big-titel">
                    Guarding Your Information: Unveiling Our Privacy Policy
                  </span>
                </div>
                <span className="text">
                  <span>Empowering Your Data Control:</span>
                  <br></br>
                  <span>
                    A Thorough Examination of Our Privacy Policy and How It Puts
                    You in Charge
                  </span>
                </span>
                <div className="privacy-policy-container08 box-shadow linear-gradient">
                  <span className="button-text">SCHEDULE A DEMO</span>
                </div>
              </div>
              <div className="privacy-policy-container09">
                <div className="privacy-policy-container10">
                  <img
                    alt="image"
                    src="https://play.teleporthq.io/static/svg/default-img.svg"
                    className="privacy-policy-image"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="privacy-policy-container11"></div>
          <div className="privacy-policy-container12">
            <span className="privacy-policy-text006">Datenschutzerklärung</span>
            <span className="privacy-policy-text007">
              1. Datenschutz auf einen Blick
            </span>
            <div className="privacy-policy-container13">
              <span className="privacy-policy-text008">
                Allgemeine Hinweise
              </span>
              <span className="privacy-policy-text009">
                <span>
                  Die folgenden Hinweise geben einen einfachen Überblick
                  darüber, was mit Ihren personenbezogenen Daten
                </span>
                <br></br>
                <span>
                  passiert, wenn Sie diese Website besuchen. Personenbezogene
                  Daten sind alle Daten, mit denen Sie
                </span>
                <br></br>
                <span>
                  persönlich identifiziert werden können. Ausführliche
                  Informationen zum Thema Datenschutz entnehmen
                </span>
                <br></br>
                <span>
                  Sie unserer unter diesem Text aufgeführten
                  Datenschutzerklärung.
                </span>
              </span>
            </div>
            <div className="privacy-policy-container14">
              <span className="privacy-policy-text017">
                <span>Datenerfassung auf dieser Website</span>
                <br></br>
                <span>
                  Wer ist verantwortlich für die Datenerfassung auf dieser
                  Website?
                </span>
              </span>
              <span className="privacy-policy-text021">
                <span>
                  Die Datenverarbeitung auf dieser Website erfolgt durch den
                  Websitebetreiber. Dessen Kontaktdaten
                </span>
                <br></br>
                <span>
                  können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“
                  in dieser Datenschutzerklärung entnehmen.
                </span>
              </span>
            </div>
            <div className="privacy-policy-container15">
              <span className="privacy-policy-text025">
                Wie erfassen wir Ihre Daten?
              </span>
              <span className="privacy-policy-text026">
                <span>
                  Ihre Daten werden zum einen dadurch erhoben, dass Sie uns
                  diese mitteilen.
                </span>
                <br></br>
                <span>Hierbei kann es sich z. B. um </span>
                <span>
                  Daten handeln, die Sie in ein Kontaktformular eingeben.
                </span>
              </span>
            </div>
            <div className="privacy-policy-container16">
              <span className="privacy-policy-text031">
                Wofür nutzen wir Ihre Daten?
              </span>
              <span className="privacy-policy-text032">
                <span>
                  Ein Teil der Daten wird erhoben, um eine fehlerfreie
                  Bereitstellung der Website zu gewährleisten. Andere
                </span>
                <br></br>
                <span>
                  Daten können zur Analyse Ihres Nutzerverhaltens verwendet
                  werden.
                </span>
              </span>
            </div>
            <div className="privacy-policy-container17">
              <span className="privacy-policy-text036">
                Welche Rechte haben Sie bezüglich Ihrer Daten?
              </span>
              <span className="privacy-policy-text037">
                <span>
                  Sie haben jederzeit das Recht, unentgeltlich Auskunft über
                  Herkunft, Empfänger und Zweck Ihrer
                </span>
                <br></br>
                <span>
                  gespeicherten personenbezogenen Daten zu erhalten. Sie haben
                  außerdem ein Recht, die Berichtigung oder
                </span>
                <br></br>
                <span>
                  Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung
                  zur Datenverarbeitung erteilt haben,
                </span>
                <br></br>
                <span>
                  können Sie diese Einwilligung jederzeit für die Zukunft
                  widerrufen. Außerdem haben Sie das Recht, unter
                </span>
                <br></br>
                <span>
                  bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen.
                </span>
                <br></br>
                <span>
                  Des Weiteren steht Ihnen ein Beschwerderecht bei der
                  zuständigen Aufsichtsbehörde zu.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können
                  Sie sich jederzeit an uns wenden.
                </span>
              </span>
            </div>
            <span className="privacy-policy-text051">2. Hosting</span>
            <div className="privacy-policy-container18">
              <span className="privacy-policy-text052">
                Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
              </span>
            </div>
            <div className="privacy-policy-container19">
              <span className="privacy-policy-text053">IONOS</span>
              <span className="privacy-policy-text054">
                <span>
                  Anbieter ist die IONOS SE, Elgendorfer Str. 57, 56410
                  Montabaur (nachfolgend IONOS). Wenn Sie unsere
                </span>
                <br></br>
                <span>
                  Website besuchen, erfasst IONOS verschiedene Logfiles
                  inklusive Ihrer IP-Adressen. Details entnehmen Sie
                </span>
                <br></br>
                <span>der Datenschutzerklärung von IONOS:</span>
                <br></br>
                <span>https://www.ionos.de/terms-gtc/terms-privacy.</span>
              </span>
              <span className="privacy-policy-text062">
                <span>
                  Die Verwendung von IONOS erfolgt auf Grundlage von Art. 6 Abs.
                  1 lit. f DSGVO. Wir haben ein
                </span>
                <br></br>
                <span>
                  berechtigtes Interesse an einer möglichst zuverlässigen
                  Darstellung unserer Website. Sofern eine
                </span>
                <br></br>
                <span>
                  entsprechende Einwilligung abgefragt wurde, erfolgt die
                  Verarbeitung ausschließlich auf Grundlage von Art.
                </span>
                <br></br>
                <span>
                  6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die
                  Einwilligung die Speicherung von Cookies oder den
                </span>
                <br></br>
                <span>
                  Zugriff auf Informationen im Endgerät des Nutzers (z. B.
                  Device-Fingerprinting) im Sinne des TTDSG
                </span>
                <br></br>
                <span>
                  umfasst. Die Einwilligung ist jederzeit widerrufbar.
                </span>
              </span>
            </div>
            <div className="privacy-policy-container20">
              <span className="privacy-policy-text074">
                Auftragsverarbeitung
              </span>
              <span className="privacy-policy-text075">
                <span>
                  Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur
                  Nutzung des oben genannten Dienstes
                </span>
                <br></br>
                <span>
                  geschlossen. Hierbei handelt es sich um einen
                  datenschutzrechtlich vorgeschriebenen Vertrag, der
                </span>
                <br></br>
                <span>
                  gewährleistet, dass dieser die personenbezogenen Daten unserer
                  Websitebesucher nur nach unseren
                </span>
                <br></br>
                <span>
                  Weisungen und unter Einhaltung der DSGVO verarbeitet.
                </span>
              </span>
            </div>
            <span className="privacy-policy-text083">
              3. Allgemeine Hinweise und Pflichtinformationen
            </span>
            <div className="privacy-policy-container21">
              <span className="privacy-policy-text084">Datenschutz</span>
              <span className="privacy-policy-text085">
                <span>
                  Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                  persönlichen Daten sehr ernst. Wir behandeln Ihre
                </span>
                <br></br>
                <span>
                  personenbezogenen Daten vertraulich und entsprechend den
                  gesetzlichen Datenschutzvorschriften sowie
                </span>
                <br></br>
                <span>dieser Datenschutzerklärung.</span>
              </span>
              <span className="privacy-policy-text091">
                <span>
                  Wenn Sie diese Website benutzen, werden verschiedene
                  personenbezogene Daten erhoben.
                </span>
                <br></br>
                <span>
                  Personenbezogene Daten sind Daten, mit denen Sie persönlich
                  identifiziert werden können. Die vorliegende
                </span>
                <br></br>
                <span>
                  Datenschutzerklärung erläutert, welche Daten wir erheben und
                  wofür wir sie nutzen. Sie erläutert auch, wie
                </span>
                <br></br>
                <span>und zu welchem Zweck das geschieht.</span>
              </span>
              <span className="privacy-policy-text099">
                <span>
                  Wir weisen darauf hin, dass die Datenübertragung im Internet
                  (z. B. bei der Kommunikation per E-Mail)
                </span>
                <br></br>
                <span>
                  Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
                  Daten vor dem Zugriff durch Dritte ist nicht
                </span>
                <br></br>
                <span>möglich.</span>
              </span>
            </div>
            <div className="privacy-policy-container22">
              <span className="privacy-policy-text105">
                Hinweis zur verantwortlichen Stelle
              </span>
              <span className="privacy-policy-text106">
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                Website ist:
              </span>
              <span className="privacy-policy-text107">
                <span>Daniel Donhauser</span>
                <br></br>
                <span>Up2Data GmbH</span>
                <br></br>
                <span>Prinz-Ludwig-Straße 4</span>
                <br></br>
                <span>80333 München</span>
              </span>
              <span className="privacy-policy-text115">
                E-Mail: info@traffls.com
              </span>
              <span className="privacy-policy-text116">
                <span>
                  Verantwortliche Stelle ist die natürliche oder juristische
                  Person, die allein oder gemeinsam mit anderen über
                </span>
                <br></br>
                <span>
                  die Zwecke und Mittel der Verarbeitung von personenbezogenen
                  Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
                </span>
                <br></br>
                <span>entscheidet.</span>
              </span>
            </div>
            <div className="privacy-policy-container23">
              <span className="privacy-policy-text122">Speicherdauer</span>
              <span className="privacy-policy-text123">
                <span>
                  Soweit innerhalb dieser Datenschutzerklärung keine speziellere
                  Speicherdauer genannt wurde, verbleiben
                </span>
                <br></br>
                <span>
                  Ihre personenbezogenen Daten bei uns, bis der Zweck für die
                  Datenverarbeitung entfällt. Wenn Sie ein
                </span>
                <br></br>
                <span>
                  berechtigtes Löschersuchen geltend machen oder eine
                  Einwilligung zur Datenverarbeitung widerrufen,
                </span>
                <br></br>
                <span>
                  werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
                  zulässigen Gründe für die Speicherung Ihrer
                </span>
                <br></br>
                <span>
                  personenbezogenen Daten haben (z. B. steuer- oder
                  handelsrechtliche Aufbewahrungsfristen); im
                </span>
                <br></br>
                <span>
                  letztgenannten Fall erfolgt die Löschung nach Fortfall dieser
                  Gründe.
                </span>
              </span>
            </div>
            <div className="privacy-policy-container24">
              <span className="privacy-policy-text135">
                <span>
                  Allgemeine Hinweise zu den Rechtsgrundlagen der
                  Datenverarbeitung auf dieser
                </span>
                <br></br>
                <span>Website</span>
              </span>
              <span className="privacy-policy-text139">
                <span>
                  Sofern Sie in die Datenverarbeitung eingewilligt haben,
                  verarbeiten wir Ihre personenbezogenen Daten auf
                </span>
                <br></br>
                <span>
                  Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2
                  lit. a DSGVO, sofern besondere Datenkategorien
                </span>
                <br></br>
                <span>
                  nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer
                  ausdrücklichen Einwilligung in die Übertragung
                </span>
                <br></br>
                <span>
                  personenbezogener Daten in Drittstaaten erfolgt die
                  Datenverarbeitung außerdem auf Grundlage von Art.
                </span>
                <br></br>
                <span>
                  49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von
                  Cookies oder in den Zugriff auf Informationen in
                </span>
                <br></br>
                <span>
                  Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt
                  haben, erfolgt die Datenverarbeitung zusätzlich
                </span>
                <br></br>
                <span>
                  auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist
                  jederzeit widerrufbar. Sind Ihre Daten zur
                </span>
                <br></br>
                <span>
                  Vertragserfüllung oder zur Durchführung vorvertraglicher
                  Maßnahmen erforderlich, verarbeiten wir Ihre
                </span>
                <br></br>
                <span>
                  Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
                  Weiteren verarbeiten wir Ihre Daten, sofern diese
                </span>
                <br></br>
                <span>
                  zur Erfüllung einer rechtlichen Verpflichtung erforderlich
                  sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
                </span>
                <br></br>
                <span>
                  Die Datenverarbeitung kann ferner auf Grundlage unseres
                  berechtigten Interesses nach Art. 6 Abs. 1 lit. f
                </span>
                <br></br>
                <span>
                  DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
                  Rechtsgrundlagen wird in den folgenden
                </span>
                <br></br>
                <span>Absätzen dieser Datenschutzerklärung informiert.</span>
              </span>
            </div>
            <div className="privacy-policy-container25">
              <span className="privacy-policy-text165">
                <span>Widerruf Ihrer Einwilligung zur Datenverarbeitung</span>
                <br></br>
              </span>
              <span className="privacy-policy-text168">
                <span>
                  Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                  ausdrücklichen Einwilligung möglich. Sie können eine
                </span>
                <br></br>
                <span>
                  bereits erteilte Einwilligung jederzeit widerrufen. Die
                  Rechtmäßigkeit der bis zum Widerruf erfolgten
                </span>
                <br></br>
                <span>Datenverarbeitung bleibt vom Widerruf unberührt.</span>
              </span>
            </div>
            <div className="privacy-policy-container26">
              <span className="privacy-policy-text174">
                <span>
                  Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
                  sowie gegen
                </span>
                <br></br>
                <span>Direktwerbung (Art. 21 DSGVO)</span>
                <br></br>
              </span>
              <span className="privacy-policy-text179">
                <span>
                  WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1
                  LIT. E ODER F DSGVO
                </span>
                <br></br>
                <span>
                  ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
                  AUS IHRER BESONDEREN
                </span>
                <br></br>
                <span>
                  SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
                  PERSONENBEZOGENEN DATEN
                </span>
                <br></br>
                <span>
                  WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE
                  BESTIMMUNGEN GESTÜTZTES
                </span>
                <br></br>
                <span>
                  PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
                  VERARBEITUNG BERUHT,
                </span>
                <br></br>
                <span>
                  ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
                  WIDERSPRUCH EINLEGEN,
                </span>
                <br></br>
                <span>
                  WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
                  VERARBEITEN, ES
                </span>
                <br></br>
                <span>
                  SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
                  VERARBEITUNG
                </span>
                <br></br>
                <span>
                  NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
                  ÜBERWIEGEN ODER DIE
                </span>
                <br></br>
                <span>
                  VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
                  VERTEIDIGUNG VON
                </span>
                <br></br>
                <span>
                  RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                </span>
              </span>
              <span className="privacy-policy-text201">
                <span>
                  WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                  DIREKTWERBUNG ZU BETREIBEN,
                </span>
                <br></br>
                <span>
                  SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
                  VERARBEITUNG SIE
                </span>
                <br></br>
                <span>
                  BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
                  WERBUNG
                </span>
                <br></br>
                <span>
                  EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT
                  SOLCHER DIREKTWERBUNG IN
                </span>
                <br></br>
                <span>
                  VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
                  PERSONENBEZOGENEN DATEN
                </span>
                <br></br>
                <span>
                  ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG
                  VERWENDET (WIDERSPRUCH
                </span>
                <br></br>
                <span>NACH ART. 21 ABS. 2 DSGVO).</span>
              </span>
            </div>
            <div className="privacy-policy-container27">
              <span className="privacy-policy-text215">
                <span>
                  Beschwerderecht bei der zuständigen Aufsichtsbehörde
                </span>
                <br></br>
              </span>
              <span className="privacy-policy-text218">
                <span>
                  Im Falle von Verstößen gegen die DSGVO steht den Betroffenen
                  ein Beschwerderecht bei einer
                </span>
                <br></br>
                <span>
                  Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                  gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
                </span>
                <br></br>
                <span>
                  oder des Orts des mutmaßlichen Verstoßes zu. Das
                  Beschwerderecht besteht unbeschadet anderweitiger
                </span>
                <br></br>
                <span>
                  verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                </span>
                <br></br>
              </span>
            </div>
            <div className="privacy-policy-container28">
              <span className="privacy-policy-text227">
                Recht auf Datenübertragbarkeit
              </span>
              <span className="privacy-policy-text228">
                <span>
                  Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                  Einwilligung oder in Erfüllung eines Vertrags
                </span>
                <br></br>
                <span>
                  automatisiert verarbeiten, an sich oder an einen Dritten in
                  einem gängigen, maschinenlesbaren Format
                </span>
                <br></br>
                <span>
                  aushändigen zu lassen. Sofern Sie die direkte Übertragung der
                  Daten an einen anderen Verantwortlichen
                </span>
                <br></br>
                <span>
                  verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </span>
                <br></br>
              </span>
            </div>
            <div className="privacy-policy-container29">
              <span className="privacy-policy-text237">
                <span>Auskunft, Berichtigung und Löschung</span>
                <br></br>
              </span>
              <span className="privacy-policy-text240">
                <span>
                  Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                  jederzeit das Recht auf unentgeltliche
                </span>
                <br></br>
                <span>
                  Auskunft über Ihre gespeicherten personenbezogenen Daten,
                  deren Herkunft und Empfänger und den
                </span>
                <br></br>
                <span>
                  Zweck der Datenverarbeitung und ggf. ein Recht auf
                  Berichtigung oder Löschung dieser Daten. Hierzu sowie
                </span>
                <br></br>
                <span>
                  zu weiteren Fragen zum Thema personenbezogene Daten können Sie
                  sich jederzeit an uns wenden.
                </span>
                <br></br>
              </span>
            </div>
            <div className="privacy-policy-container30">
              <span className="privacy-policy-text249">
                Recht auf Einschränkung der Verarbeitung
              </span>
              <span className="privacy-policy-text250">
                <span>
                  Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen.
                </span>
                <br></br>
                <span>
                  Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf
                  Einschränkung der Verarbeitung besteht in
                </span>
                <br></br>
                <span>folgenden Fällen:</span>
                <br></br>
              </span>
              <span className="privacy-policy-text257">
                <span>
                  - Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                  personenbezogenen Daten bestreiten, benötigen wir
                </span>
                <br></br>
                <span>
                  in der Regel Zeit, um dies zu überprüfen. Für die Dauer der
                  Prüfung haben Sie das Recht, die
                </span>
                <br></br>
                <span>
                  Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                  zu verlangen.
                </span>
              </span>
              <span className="privacy-policy-text263">
                <span>
                  - Wenn die Verarbeitung Ihrer personenbezogenen Daten
                  unrechtmäßig geschah/geschieht, können Sie
                </span>
                <br></br>
                <span>
                  statt der Löschung die Einschränkung der Datenverarbeitung
                  verlangen.
                </span>
              </span>
              <span className="privacy-policy-text267">
                <span>
                  - Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen,
                  Sie sie jedoch zur Ausübung,
                </span>
                <br></br>
                <span>
                  Verteidigung oder Geltendmachung von Rechtsansprüchen
                  benötigen, haben Sie das Recht, statt der
                </span>
                <br></br>
                <span>
                  Löschung die Einschränkung der Verarbeitung Ihrer
                  personenbezogenen Daten zu verlangen.
                </span>
              </span>
              <span className="privacy-policy-text273">
                <span>
                  - Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
                  eingelegt haben, muss eine Abwägung zwischen
                </span>
                <br></br>
                <span>
                  Ihren und unseren Interessen vorgenommen werden. Solange noch
                  nicht feststeht, wessen Interessen
                </span>
                <br></br>
                <span>
                  überwiegen, haben Sie das Recht, die Einschränkung der
                  Verarbeitung Ihrer personenbezogenen Daten
                </span>
                <br></br>
                <span>zu verlangen.</span>
              </span>
              <span className="privacy-policy-text281">
                <span>
                  Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                  eingeschränkt haben, dürfen diese Daten – von
                </span>
                <br></br>
                <span>
                  ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder
                  zur Geltendmachung, Ausübung oder
                </span>
                <br></br>
                <span>
                  Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
                  einer anderen natürlichen oder
                </span>
                <br></br>
                <span>
                  juristischen Person oder aus Gründen eines wichtigen
                  öffentlichen Interesses der Europäischen Union oder
                </span>
                <br></br>
                <span>eines Mitgliedstaats verarbeitet werden.</span>
              </span>
            </div>
            <div className="privacy-policy-container31">
              <span className="privacy-policy-text291">
                SSL- bzw. TLS-Verschlüsselung
              </span>
              <span className="privacy-policy-text292">
                <span>
                  Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                  Übertragung vertraulicher Inhalte, wie zum
                </span>
                <br></br>
                <span>
                  Beispiel Bestellungen oder Anfragen, die Sie an uns als
                  Seitenbetreiber senden, eine SSL- bzw. TLS-
                </span>
                <br></br>
                <span>
                  Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                  daran, dass die Adresszeile des Browsers von
                </span>
                <br></br>
                <span>
                  „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
                  Ihrer Browserzeile.
                </span>
                <br></br>
              </span>
              <span className="privacy-policy-text301">
                <span>
                  Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können
                  die Daten, die Sie an uns übermitteln, nicht
                </span>
                <br></br>
                <span>von Dritten mitgelesen werden.</span>
              </span>
            </div>
            <span className="privacy-policy-text305">
              4. Datenerfassung auf dieser Website
            </span>
            <div className="privacy-policy-container32">
              <span className="privacy-policy-text306">
                Anfrage per E-Mail, Telefon oder Telefax
              </span>
              <span className="privacy-policy-text307">
                <span>
                  Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren,
                  wird Ihre Anfrage inklusive aller daraus
                </span>
                <br></br>
                <span>
                  hervorgehenden personenbezogenen Daten (Name, Anfrage) zum
                  Zwecke der Bearbeitung Ihres Anliegens
                </span>
                <br></br>
                <span>
                  bei uns gespeichert und verarbeitet. Diese Daten geben wir
                  nicht ohne Ihre Einwilligung weiter.
                </span>
                <br></br>
              </span>
              <span className="privacy-policy-text314">
                <span>
                  Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
                  Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                </span>
                <br></br>
                <span>
                  der Erfüllung eines Vertrags zusammenhängt oder zur
                  Durchführung vorvertraglicher Maßnahmen
                </span>
                <br></br>
                <span>
                  erforderlich ist. In allen übrigen Fällen beruht die
                  Verarbeitung auf unserem berechtigten Interesse an der
                </span>
                <br></br>
                <span>
                  effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6
                  Abs. 1 lit. f DSGVO) oder auf Ihrer
                </span>
                <br></br>
                <span>
                  Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
                  abgefragt wurde; die Einwilligung ist jederzeit
                </span>
                <br></br>
                <span>widerrufbar.</span>
              </span>
              <span className="privacy-policy-text326">
                <span>
                  Die von Ihnen an uns per Kontaktanfragen übersandten Daten
                  verbleiben bei uns, bis Sie uns zur Löschung
                </span>
                <br></br>
                <span>
                  auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
                  der Zweck für die Datenspeicherung entfällt
                </span>
                <br></br>
                <span>
                  (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
                  Zwingende gesetzliche Bestimmungen –
                </span>
                <br></br>
                <span>
                  insbesondere gesetzliche Aufbewahrungsfristen – bleiben
                  unberührt.
                </span>
              </span>
            </div>
          </div>
          <NewsletterComponent rootClassName="newsletter-component-root-class-name6"></NewsletterComponent>
          <BottomBar></BottomBar>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
